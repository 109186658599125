<template lang="">
  <div>
    <!-- SECTION Reference Data Search -->
    <BCard
      header-class="py-1"
      body-class="pb-1"
    >
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('Reference Data Search') }}
        </div>
      </template>

      <IAmOverlay :loading="loading">

        <BRow>
          <!-- ANCHOR Type -->
          <BCol
            md="6"
            cols="12"
            lg="4"
            class="mt-50 mt-lg-0"
          >
            <b-form-group
              :label="$t('utils.type')"
              label-for="type"
              class="w-100 mb-0"
            >
              <v-select
                v-model="type"
                class="w-100"
                style="font-size: 1rem;"
                :options="typeOptions"
                label="label"
                clearable
                :placeholder="$t('utils.typePlaceholder')"
                :reduce="val => val"
              >
                <template #option="data">
                  <span>
                    {{ $te(`utils.${data.label}`) ? `${$t(`utils.${data.label}`)} (${data.label})` : data.label }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $te(`utils.${data.label}`) ? `${$t(`utils.${data.label}`)} (${data.label})` : data.label }}
                  </span>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </BCol>

          <!-- ANCHOR Code -->
          <BCol
            md="6"
            cols="12"
            lg="4"
            class="mt-50 mt-lg-0"
          >
            <b-form-group
              :label="$t('utils.code')"
              label-for="code"
              class="w-100 mb-0"
            >
              <b-form-input
                id="code"
                v-model="code"
                :placeholder="$t('utils.codePlaceholder')"
                :name="$t('utils.code')"
                :formatter="removeAccentsUpperCaseFormatterNotTrim"
                @keydown.enter.prevent="submitSearch"
              />
            </b-form-group>
          </BCol>

          <!-- ANCHOR - BUTTONs -->
          <BCol
            cols="12"
            md="6"
            lg="4"
            class="mt-50 mt-lg-0 d-flex-center justify-content-md-start align-items-end gap-2"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="font-weight-bolder p-75 text-nowrap"
              variant="outline-warning"
              :disabled="isDisabledButtonSubmit"
              @click="submitSearch"
            >
              <feather-icon
                v-if="!isMobileView"
                icon="SearchIcon"
                class="text-warning"
              />
              <span
                class="align-middle text-warning font-weight-bolder"
              >
                {{ $t('search') }}
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="font-weight-bolder p-75 text-nowrap text-danger"
              variant="outline-danger"
              :disabled="isDisabledButtonClear"
              @click="clearFilter"
            >
              <feather-icon
                v-if="!isMobileView"
                icon="TrashIcon"
              />
              <span
                class="align-middle font-weight-bolder"
              >
                {{ $t('clear') }}
              </span>
            </b-button>
          </BCol>
        </BRow>

        <div
          v-if="response !== null"
          class="mt-2"
        >
          <div class="font-weight-bolder text-uppercase my-1">
            {{ $t('utils.result') }}
          </div>
          <div v-if="!response">
            {{ $t('utils.noResult') }}
          </div>
          <div v-else>
            <div
              v-for="(value, key) in response"
              :key="key"
            >
              <span><span class="font-weight-bolder">{{ $te(`utils.${key}`) ? $t(`utils.${key}`) : key }}:</span> {{ value }}</span>
            </div>
          </div>
        </div>
      </IAmOverlay>
    </BCard>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'

import { apiGeneral } from '@/api'

import { removeAccentsUpperCaseFormatterNotTrim } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    vSelect: () => import('vue-select'),
  },
  setup() {
    const { toastError } = useToast()
    const typeOptions = ['AIRPORT', 'CARRIER', 'CITY', 'COUNTRY', 'EQUIPMENT',
    // 'TIMESTAMP' // chưa support
    ]

    // ANCHOR - DEFINE FILTERS
    const type = ref()
    const code = ref()
    const loading = ref(false)
    const response = ref(null)
    const isDisabledButtonSubmit = computed(() => !(code.value && type.value) || loading.value)
    const isDisabledButtonClear = computed(() => (!code.value && !type.value) || loading.value)

    function submitSearch() {
      if (isDisabledButtonSubmit.value) return
      response.value = null
      loading.value = true
      const payload = {
        type: type.value,
        code: code.value,
      }
      apiGeneral.referenceDataSearch(payload)
        .then(res => {
          response.value = res[type.value.toLowerCase()]
        })
        .catch(() => {
          toastError('Lỗi tìm kiếm')
        })
        .finally(() => {
          loading.value = false
        })
    }

    function clearFilter() {
      code.value = undefined
      type.value = undefined
    }

    return {
      type,
      code,
      loading,
      isDisabledButtonSubmit,
      isDisabledButtonClear,
      submitSearch,
      clearFilter,
      removeAccentsUpperCaseFormatterNotTrim,
      typeOptions,
      response,
    }
  },
}
</script>

<style lang="">

</style>
